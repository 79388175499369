body, html {
    height: 100%;
  }

.logoContainer{
    position: relative;
    max-width: 50px;
    background-color: #ffffff;
}

.bgContainer {
    position: absolute;
    background-image: url('../Images/bgimgLogin.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width: -webkit-fill-available;
    left: 487px;
    height: -webkit-fill-available;
    top: 0px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.plainContainer{
    background-color: #ffffff;
}

.loginForm{
background-color: yellowgreen;
margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}

.formElements{
    margin-left: 4%;
}

.bgRegistrationContainer{
    position: absolute;
    background: url('../Images/bgimgRegistration.jpeg'), #286090;
    box-shadow: inset 0 0 0 2000px #28609078;
    background-repeat: no-repeat;
    background-size: cover;
    width: -webkit-fill-available;
    left: 487px;
    height: -webkit-fill-available;
    top: 0px;
}



