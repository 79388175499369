/*body{
     background: url(../Images/bgimgTermsandConditions.jpeg) no-repeat center center fixed; 
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
     box-shadow: inset 0 0 0 2000px rgb(20 20 20 / 70%);
 }*/

 .TnCbgContainer{
    width: 712px;
    height: 470px;
    background-color: #ffffff;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
 }

 h4{
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 36px;
padding-left: 50px;
color: #286090;
margin-bottom: 20px;
 }

 /*p{
    padding-left: 50px;
    height: 300px;
    overflow: scroll;
 }*/

 article{
    padding: 0 25px 20px 0;
    /*font-family: 'Roboto';*/
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: justify;
color: #000000;
 }
 
 .custom footer{
     position: absolute;
     width: 682px;
     top: 88%;
     padding: 15px;
     background: #EDEDED;
     box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.16);
     border-radius: 0px 0px 4px 4px;
 }